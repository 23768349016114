import styled from "styled-components"
import { GlobalContext, GlobalProvider } from "../utils/global"
import { useContext } from "react"
import i18next from "i18next"
import LanguageDetector from "i18next-browser-languagedetector"
import gsap from 'gsap'
import { useGSAP } from "@gsap/react"
import { ScrollTrigger } from "gsap/ScrollTrigger"
import { MotionPathPlugin } from "gsap/MotionPathPlugin"
import ScrollButton from "./ScrollButton"

const AppContainer = styled.div`
  position: relative;
`

function App({ children }) {
  const { device } = useContext(GlobalContext)

  i18next.use(LanguageDetector).init({
    supportedLngs: ['fr', 'en'],
    fallbackLng: 'en',
    resources: {
      fr: {
        translation: {
          "nav-home" : "Accueil",
          "nav-about" : "A propos",
          "nav-project" : "Nos projets",
          "nav-contact" : "Contact",
          "nav-blog": "Blog",
          "hero-title" : "Web-boost vers votre réussite.",
          "hero-catchphrase" : "La conception graphique de votre site, du design web, de l’ergonomie et de la redaction de contenu.",
          "hero-description" : "Il est possible de réaliser votre projet web sur mesure avec Web-boost. Notre agence digitale crée votre site internet ou Ecommerce pour qu'il soit agréable, moderne et en première page de Google afin d'être bien référencé.",
          "hero-cta-more" : "En savoir plus",
          "hero-cta-contact" : "Nous contacter",
          "customer-section-title" : "Ils nous font confiance",
          "project-section-title" : "Nos réalisations récentes",

          "start-project-section-title" : "Comment bien démarrer son projet\u00A0?",
          "first-step-title" : "Votre besoin est notre priorité",
          "first-step-description" : "Créez de nouveaux appels d'offres et conservez vos clients existants. La performance des dispositifs élaborés est la seule vérité au sein de notre agence web ! Notre agence assure le référencement naturel ou payant, de fidélisation et de conversion.",
          "second-step-title" : "Amorcez votre projet avec une approche solide",
          "second-step-description" : "Nous créons votre vitrine ou votre boutique en ligne avec les meilleures technologies afin d'être responsive design et optimisé pour le référencement naturel. Nous concevons aussi des applications mobiles, afin que vous soyez toujours à la page.",
          "last-step-title" : "À votre écoute",
          "last-step-description" : "Est-ce que vous avez besoin d'un devis ou d'un accompagnement pour créer un site internet ou une boutique en ligne pour vendre sur Internet ? Est-ce que vous souhaitez réaliser un projet web sur mesure ? Vous cherchez une agence de communication spécialisée dans le référencement naturel d'un site ou un conseil en communication ? Communiquez avec notre agence web digitale.",
          
          "about-section-title" : "A propos",
          "about-section-second-title-history" : "Historique",
          "about-section-history-description" : "Nous produisons des applications ou sites web performants, modernes et sécurisés.Nos solutions sont équipées d'un espace d'administration ergonomique, sont optimisées pour le référencement naturel et sont compatibles avec les tablettes et les mobiles.",
          "about-section-second-title-skill" : "Nos domaines de compétences",
          "skill-one-title" : "Site Internet",
          "skill-one-description": "Nous avons pour objectif de créer des sites web, vitrine ou Ecommerce. Nous nous occupons de tous les projets web.",
          "skill-two-title" : "Conception & Réalisation de maquette",
          "skill-two-description": "Nous offrons un service unique qui se concentre sur l'inventivité, la créativité, la force de propositions et le partage d'idées. Notre engagement est total jusqu'à ce que notre mission soit accomplie.",
          "skill-three-title" : "Maintenance & Mise à jour",
          "skill-three-description": "Les équipes et l'expertise de Web-boost sont là pour vous accompagner dans le renouvellement de votre projet numérique. Nous sommes une agence web digitale qui offre tous les services nécessaires pour la refonte votre projet.",
          "skill-four-title" : "Application mobile",
          "skill-four-description": "En plus du développement de sites web, différents langages de programmation permettent la création d'applications mobiles. Nos spécialistes techniques prennent le temps d'étudier vos enjeux et vos contraintes.",
          "skill-five-title" : "Référencement naturel SEO",
          "skill-five-description": "Pour mener une stratégie de référencement naturel, il est important de se concentrer sur la qualité du contenu et de la sémantique, du code HTML, mais également la bonne intégration des liens externes et internes dans les pages.",
          "skill-six-title" : "Formation",
          "skill-six-description": "Le développement web et mobile est une profession qui se spécialise dans la conception, le développement et l'entretien d'applications et de sites Web. Il s'agit d'utiliser divers langages de programmation.",
          "techno-section-title" : "Notre technique",
          "question-section-title" : "Foire aux questions",
          "question-catchphrase" : "Des questions avant de prendre des décisions ? Nous y répondons ici !",
          "question-one" : "Quels sont les avantages de créer un site internet\u00A0?",
          "question-one-answer" : "Augmenter sa visibilité, sa clientèle, la qualité de son service après-vente mais également faire face à la concurrence. Ces stratégies reposent en grande partie sur l'identité web, Web-Boost vous offre cette opportunité.",
          "question-two" : "Dois-je créer un site vitrine\u00A0?",
          "question-two-answer" : "C’est en quelque sorte votre carte de visite sur le web. Sa fonction principale est de présenter votre entreprise, vos produits ou services, et de mettre à disposition les informations inhérentes à votre boutique (horaires, directions, contact, etc.).",
          "question-three" : "Devrais-je créer un e-commerce\u00A0?",
          "question-three-answer" : "Avec un e-commerce, vous pouvez bénéficier de tous les avantages d’un site vitrine, tout en permettant à vos clients d’effectuer leurs achats en ligne. Si vous souhaitez numériser votre commerce, c’est donc l’option idéale.",
          "question-four" : "Forum, blog ou site complet\u00A0?",
          "question-four-answer" : "Le forum est approprié si vous avez une communauté qui désire échanger, et si vous voulez rassembler des gens sur un lieu virtuel. Le blog, quant à lui, sert à présenter votre contenu, utile si vous avez des gens qui vous suivent ou si vous êtes un influenceur. Le site complet est parfait si vous souhaitez rassembler plusieurs des sites cités sur cette page en un seul site, par exemple : forum et e-commerce.",
          "question-five" : "Mise à jour\u00A0? Pourquoi\u00A0?",
          "question-five-answer" : "Mettre à jour son site web, c'est augmenter le nombre d'informations, le nombre de pages et le contenu de son site internet. La maintenance et l'actualisation régulières du site web sont des facteurs importants pour l'optimisation du référencement d'un site.",
          "contact-section-codeur" : "Plateforme Codeur",
          "contact-section-malt" : "Plateforme Malt",
          "contact-section-network" : "Suivez nous sur nos réseaux sociaux",
          "footer-legal-info" : "Informations légales",
          "footer-policy" : "Politique de confidentialité",
          "footer-legal" : "Mentions légales",
          "footer-cgv": "CGV",
          "modal-title" : "Votre message a été envoyé !",
          "modal-text": "Nous vous remercions pour votre message. Nous allons traiter votre demande dans les meilleurs délais.",
          "contact-page-form-title": "Contactez-nous",
          "contact-page-form-input-name": "Nom",
          "contact-page-form-input-company": "Société",
          "contact-page-form-input-email": "Email",
          "contact-page-form-input-phone": "Télephone",
          "contact-page-form-input-message": "Message",
          "contact-page-form-button-send": "Envoyer"
        }
      },
      en: {
        translation: {
          "nav-home" : "Home",
          "nav-about" : "About",
          "nav-project" : "Our project",
          "nav-contact" : "Contact",
          "nav-blog": "Blog",
          "hero-title": "Web-boost towards your success.",
          "hero-catchphrase": "The graphic design of your site, web design, ergonomics and content writing.",
          "hero-description" : "It is possible to create your tailor-made web project with Web-boost. Our digital agency creates your website or Ecommerce so that it is pleasant, modern and on the first page of Google in order to be well referenced.",
          "hero-cta-more" : "Find out more",
          "hero-cta-contact" : "Contact us",
          "customer-section-title" : "They trust us",
          "project-section-title" : "Our recent achievements",

          "start-project-section-title" : "How to get your project off to a good start ?",
          "first-step-title" : "Your needs are our priority",
          "first-step-description" : "Create new tenders and retain your existing customers. The performance of the devices developed is the only truth within our web agency! Our agency provides natural or paid referencing, loyalty and conversion.",
          "second-step-title" : "Start your project with a solid approach",
          "second-step-description" : "We create your showcase or online store with the best technologies in order to be responsive design and optimized for natural referencing. We also design mobile applications, so that you are always up to date.",
          "last-step-title" : "At your service",
          "last-step-description" : "Do you need a quote or support to create a website or an online store to sell on the Internet? Do you want to create a tailor-made web project? Are you looking for a communications agency specializing in the natural referencing of a site or communications consultancy? Contact our digital web agency.",
          
          "about-section-title" : "About us",
          "about-section-second-title-history" : "Historical",
          "about-section-historic-description" : "We produce high-performance, modern and secure applications or websites. Our solutions are equipped with an ergonomic administration space, are optimized for natural referencing and are compatible with tablets and mobiles.",
          "about-section-second-title-skill" : "Our areas of expertise",
          "skill-one-title" : "Website",
          "skill-one-description": "Our goal is to create websites, showcase or Ecommerce. We take care of all web projects.",
          "skill-two-title" : "Design & Creation of models",
          "skill-two-description": "We offer a unique service that focuses on inventiveness, creativity, the strength of proposals and the sharing of ideas. Our commitment is total until our mission is accomplished.",
          "skill-three-title" : "Maintenance & Updates",
          "skill-three-description": "Web-boost's teams and expertise are there to support you in renewing your digital project. We are a digital web agency that offers all the services necessary to redesign your project.",
          "skill-four-title" : "Mobile application",
          "skill-four-description": "In addition to website development, different programming languages ​​allow the creation of mobile applications. Our technical specialists take the time to study your issues and constraints.",
          "skill-five-title" : "SEO natural referencing",
          "skill-five-description": "To carry out a natural referencing strategy, it is important to focus on the quality of the content and semantics, of the HTML code, but also the good integration of external and internal links in the pages.",
          "skill-six-title" : "Training",
          "skill-six-description": "Web and mobile development is a profession that specializes in the design, development and maintenance of applications and websites. It involves using various programming languages.",
          "techno-section-title" : "Our technique",
          "question-section-title" : "Frequently asked questions",
          "question-catchphrase" : "Any questions before making decisions ? We'll answer them here!",
          "question-one" : "What are the advantages of creating a website\u00A0?",
          "question-one-answer" : "Increase your visibility, your clientele, the quality of your after-sales service and also face up to the competition. These strategies are largely based on web identity, and Web-Boost offers you this opportunity.",
          "question-two" : "Do I need to create a showcase site\u00A0?",
          "question-two-answer" : "In a way, it's your business card on the web. Its main function is to present your company, products or services, and to provide information about your shop (opening hours, directions, contact details, etc.).",
          "question-three" : "Should I set up an e-commerce\u00A0?",
          "question-three-answer" : "With an e-commerce site, you can enjoy all the benefits of a shop window site, while allowing your customers to make their purchases online. So if you're looking to digitise your business, this is the ideal option.",
          "question-four" : "Forum, blog or complete site\u00A0?",
          "question-four-answer" : "The forum is appropriate if you have a community that wants to exchange ideas, and if you want to bring people together in a virtual place. The blog, on the other hand, is used to present your content, which is useful if you have people following you or if you are an influencer. The full site is perfect if you want to combine several of the sites listed on this page into a single site, for example: forum and e-commerce.",
          "question-five" : "Update\u00A0? Why update\u00A0?",
          "question-five-answer" : "Updating your website means increasing the amount of information, the number of pages and the content of your website. Regular website maintenance and updating are important factors in optimising a site's referencing.",
          "contact-section-codeur" : "Plateform Codeur",
          "contact-section-malt" : "Plateform Malt",
          "contact-section-network" : "Follow us on our social networks",
          "footer-legal-info" : "Legal information",
          "footer-policy" : "Privacy policy",
          "footer-legal" : "Legal notice",
          "footer-cgv": "CGV",
          "modal-title" : "Your message has been sent !",
          "modal-text": "We thank you for your message. We will process your request as soon as possible.",
          "contact-page-form-title": "Contact us",
          "contact-page-form-input-name": "Name",
          "contact-page-form-input-company": "Company",
          "contact-page-form-input-email": "Email",
          "contact-page-form-input-phone": "Phone",
          "contact-page-form-input-message": "Message",
          "contact-page-form-button-send": "Send"
        }
      }
    }
  })

  // i18next.changeLanguage('en')
  gsap.registerPlugin(ScrollTrigger)
  gsap.registerPlugin(MotionPathPlugin)
  gsap.registerPlugin(useGSAP);
  
  return (
    <GlobalProvider>
      <AppContainer>
        { children }
        <ScrollButton />
      </AppContainer>
    </GlobalProvider>
  )
}

export default App
