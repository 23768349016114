import styled from "styled-components"
import { SpaceSection } from "../../utils/Atoms"
import SectionTitle from "../SectionTitle"
import { Link } from "react-router-dom"
import image from "../../assets/pexels-janko-ferlic-590478.jpg"
import { ReactComponent as SmallStar } from "../../assets/smallStar.svg"
import { ReactComponent as BigStar } from "../../assets/bigStar.svg"
import orion from "../../assets/orion.svg"
import casiope from "../../assets/casiope.svg"
import bigbear from "../../assets/bigbear.svg"
import { articles } from "../../utils/data.js"

const SpaceSectionElt = styled(SpaceSection)`
    padding: 9rem 1.5rem 17rem 1.5rem;
`

const ArticlesContainer = styled.div`
    display: flex;
    overflow-x: scroll;
    position: relative;
    z-index: 1;
`

const ArticleLink = styled(Link)`
    width: 30%;
    min-width: 30%;
    text-decoration: none;
    color: white;

    &:not(:last-child) {
        margin-right: 2rem;
    }

    @media only screen and (min-width: 768px) and (max-width: 1024px) {
        width: 45%;
        min-width: 45%;
    }

    @media only screen and (max-width: 767px) {
        width: 90%;
        min-width: 90%;
    }
`

const ImageContainer = styled.div`
    width: 100%;
    height: 15rem;

    @media only screen and (max-width: 767px) {
        height: 20rem;
    }
`

const Image = styled.img`
    &.article {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
    }
    &.orion {
        position: absolute;
        top: 10%;
        left: 0;
        width: 20%;
        max-width: 218px;
        transform: rotate(53deg);
    }
    &.bigBear {
        position: absolute;
        top: 14%;
        left: 81%;
        transform: rotate(62deg);
        width: 16%;
        max-width: 180px;
    }
    &.casiope {
        position: absolute;
        top: 80%;
        left: 82%;
        transform: rotate(-45deg);
        width: 18%;
        max-width: 200px;
    }

    @media only screen and (max-width: 767px) {
        &.orion {
            width: 24%;
        }
        &.bigBear {
            left: 77%;
            width: 19%;
        }
        &.casiope {
            top: 77%;
            left: 76%;
            width: 24%;
        }
    }
`

const ArticleTitle = styled.h4`
    text-transform: uppercase;
    font-size: 1.4rem;
`

const SmallStarElt = styled(SmallStar)`
    position: absolute;

    &.one {
        top: 18%;
        left: 35%;
    }
    &.two {
        top: 10%;
        left: 19%;
    }
    &.three {
        top: 17%;
        left: 55%;
    }
    &.four {
        top: 30%;
        left: 73%;
    }
    &.five {
        top: 15%;
        left: 60%;
    }
    &.six {
        top: 79%;
        left: 5%;
    }
    &.seven {
        top: 75%;
        left: 46%;
    }
    &.eight {
        top: 85%;
        left: 35%;
    }
    &.nine {
        top: 82%;
        left: 73%;
    }
    &.ten {
        top: 81%;
        left: 50%;
    }

    @media only screen and (max-width: 767px) {
        &.four {
            top: 26%;
            left: 73%;
        }
        &.nine {
            top: 86%;
            left: 58%;
        }
    }
`

const BigStarElt = styled(BigStar)`
    position: absolute;

    &.one {
        top: 31%;
        left: 31%;
    }
    &.two {
        top: 14%;
        left: 36%;
    }
    &.three {
        top: 31%;
        left: 76%;
    }
    &.four {
        top: 30%;
        left: 41%;
    }
    &.five {
        top: 21%;
        left: 69%;
    }
    &.six {
        top: 76%;
        left: 19%;
    }
    &.seven {
        top: 78%;
        left: 52%;
    }
    &.eight {
        top: 86%;
        left: 68%;
    }
    &.nine {
        top: 79%;
        left: 82%;
    }
    &.ten {
        top: 80%;
        left: 32%;
    }

    @media only screen and (min-width: 768px) and (max-width: 1024px) {
        &.one {
            top: 28%;
            left: 29%;
        }
        &.three {
            top: 28%;
            left: 76%;
        }
        &.five {
            top: 18%;
            left: 69%;
        }
    }

    @media only screen and (max-width: 767px){
        &.one {
            top: 25%;
            left: 23%;
        }
        &.three {
            top: 21%;
            left: 4%;
        }
        &.four {
            top: 26%;
            left: 41%;
        }
        &.five {
            top: 24%;
            left: 79%;
        }
        &.eight {
            top: 88%;
            left: 94%;
        }
        &.nine {
            top: 75%;
            left: 68%;
        }
    }
`

function NewsSection() {
    return (
        <SpaceSectionElt>
            <Image src={orion} className="orion" alt="image d'une constelation"/>
            <Image src={bigbear} className="bigBear" alt="image d'une constelation"/>
            <Image src={casiope} className="casiope" alt="image d'une constelation"/>
            <SmallStarElt className="one"/>
            <SmallStarElt className="two"/>
            <SmallStarElt className="three"/>
            <SmallStarElt className="four"/>
            <SmallStarElt className="five"/>
            <SmallStarElt className="six"/>
            <SmallStarElt className="seven"/>
            <SmallStarElt className="eight"/>
            <SmallStarElt className="nine"/>
            <SmallStarElt className="ten"/>

            <BigStarElt className="one"/>
            <BigStarElt className="two"/>
            <BigStarElt className="three"/>
            <BigStarElt className="four"/>
            <BigStarElt className="five"/>
            <BigStarElt className="six"/>
            <BigStarElt className="seven"/>
            <BigStarElt className="eight"/>
            <BigStarElt className="nine"/>
            <BigStarElt className="ten"/>

            <SectionTitle label="Nos actualités" id="news-title" className={"white"} />

            <ArticlesContainer>
                { articles.map((article, index) => (
                    <ArticleLink to={ `/blog/${ article.urlTitle }` } key={ index }>
                        <ImageContainer>
                            <Image className="article" src={ article.image } alt={ article.title } />
                        </ImageContainer>
                        <ArticleTitle>{ article.title }</ArticleTitle>
                    </ArticleLink>
                ))}
            </ArticlesContainer>
        </SpaceSectionElt>
    )
}

export default NewsSection