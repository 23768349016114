import styled from "styled-components"
import SectionTitle from "../SectionTitle"
import planet from '../../assets/planet.svg'
import codeur from '../../assets/codeur.svg'
import phone from '../../assets/phone.svg'
import location from '../../assets/location-pin-svgrepo-com.svg'
import arobase from '../../assets/arobase.svg'
import malt from '../../assets/malt.svg'
import { ReactComponent as Linkedin } from "../../assets/linkedin.svg"
import { ReactComponent as Facebook } from "../../assets/facebook.svg"
import { ReactComponent as Instagram } from "../../assets/instagram-round-svgrepo-com.svg"
import { ReactComponent as Whatsapp } from "../../assets/whatsapp-128-svgrepo-com.svg"
import { Link } from "react-router-dom"
import i18next from "i18next"
import { useGSAP } from "@gsap/react"
import gsap from "gsap"

const Section = styled.section`
    padding: 0 2rem 4rem 2rem;

    @media only screen and (max-width: 767px) {
        padding: 0 1rem 4rem 1rem;
    }
`

const Container = styled.div`
    padding: 4rem;
    position: relative;
    width: 868px;
    margin: auto;

    @media only screen and (min-width : 768px) and (max-width : 1024px) {
        width: 740px;
        padding: 0;
        margin: 0 auto 4rem auto;
    }
    @media only screen and (max-width : 767px) {
        width: auto;
        padding: 0 0 4rem 0;
    }
`

const ImageContainer = styled.div`
    &.contact-planet {
        width: 500px;
    }
    
    &.contact {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 60px;
        height: 60px;
        padding: 10px;
        border-radius: 50%;
        border: solid 3px #130633;
        box-sizing: border-box;
    }

    @media only screen and (min-width : 768px) and (max-width : 1024px) {
        &.contact-planet {
            width: 350px;
        }
    }
    @media only screen and (max-width : 767px) {
        &.contact-planet {
            display: none;
        }
        &.contact {
            border: none;
            background-color: #FFBE00;
        }
    }
`

const StyledLink = styled.a`
    text-decoration: none;
    cursor: pointer;

    &.contact-network {
        width: 50px;
        height: 50px;
        margin: 0 1.3rem;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    svg {
        filter: brightness(0) saturate(100%) invert(4%) sepia(41%) saturate(5049%) hue-rotate(250deg) brightness(122%) contrast(106%);
        width: 100%;
    }
    @media only screen and (min-width : 768px) and (max-width : 1024px) {
        &.contact-network {
            margin: 0 1rem;
        }
    }

    @media only screen and (max-width: 767px) {
        &.contact-network {
            width: 40px;
            height: 40px;
            margin: 0 0.8rem;
        }
    }
`

const Image = styled.img`
    width: 100%;
`

const ContactContainer = styled.article`
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;

    &.phone {
        top: 12%;
        left: 530px;
    }
    &.mail {
        top: 28%;
        left: 575px;
    }
    &.location {
        top: 43%;
        left: 603px;
    }
    &.malt {
        top: 58%;
        left: 575px;
    }
    &.codeur {
        top: 73%;
        left: 530px;
    }

    @media only screen and (min-width : 768px) and (max-width : 1024px) {
        &.phone {
            top: 0;
            left: 325px;
        }
        &.mail {
            top: 20%;
            left: 370px;
        }
        &.location {
            top: 40%;
            left: 404px;
        }
        &.malt {
            top: 60%;
            left: 370px;
        }
        &.codeur {
            top: 80%;
            left: 325px;
        }
    }

    @media only screen and (max-width : 767px) {
        position: relative;
        justify-content: flex-start;

        &.phone, &.mail, &.location, &.malt, &.codeur {
            top: 0;
            left: 0;
            margin: 0 0 1rem 0;
            border-radius: 7px;
            background: #FFBE00;
        }
    }
`

const ContactLink = styled.a`
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    color: #130633;
`

const TextContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0 0 0 10px;
    font-size: 1.4rem;

    @media only screen and (max-width: 767px) {
        font-size: 1.2rem;
        padding: 0;
    }
`

const Text = styled.span`
    &.contact-network {
        font-size: 2.3rem;
        font-weight: 700;
    }

    @media only screen and (max-width : 1024px) {
        &.contact-network {
            font-size: 1.2rem;
        }
    }

    @media only screen and (max-width: 767px) {
        text-align: center;

        &.contact-network {
            padding: 0 0 2rem 0;
            text-align: center;
        }
    }
`

const NetworksContainer = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    @media only screen and (max-width: 767px) {
        flex-direction: column;
    }
`

const Content = styled.div`
    display: flex;
`

function ContactSection() {
    useGSAP(() => {
        const mm = gsap.matchMedia()
        mm.add("(max-width: 767px)", () => {
            gsap.from('.contact-container', {
                x: -100,
                duration: 1,
                delay: 1,
                opacity: 0,
                stagger: {
                    amount: 1
                },
                scrollTrigger: {
                    trigger: '.contacts-container',
                    start: "top 65%",
                }
            })
        })

        mm.add("(min-width: 768px)", () => {
            gsap.from('.contact-planet', {
                duration: 1,
                delay: 1,
                opacity: 0,
                stagger: {
                    amount: .5
                },
                scrollTrigger: {
                    trigger: '.contacts-container',
                    start: "top 65%",
                }
            })
            gsap.from('.contact-container', {
                duration: 1,
                delay: 1,
                opacity: 0,
                stagger: {
                    amount: .5
                },
                scrollTrigger: {
                    trigger: '.contacts-container',
                    start: "top 65%",
                }
            })
            gsap.from('.contact-network', {
                duration: 1,
                delay: 2,
                opacity: 0,
                stagger: {
                    amount: .5
                },
                scrollTrigger: {
                    trigger: '.contacts-container',
                    start: "top 65%",
                }
            })
        })
    })

    return (
        <Section id="contact_section">
            <SectionTitle label={"Contact"} id="contact-title" />

            <Container className="contacts-container">
                <ImageContainer className="contact-planet">
                    <Image src={planet} alt="image d'une planète" />
                </ImageContainer>

                <ContactContainer className="phone contact-container">
                    <ImageContainer className="contact">
                        <Image src={phone} alt="logo téléphone" />
                    </ImageContainer>
                    <TextContainer>
                        <ContactLink href="tel:0786524195">07 86 52 41 95</ContactLink>
                    </TextContainer>
                </ContactContainer>

                <ContactContainer className="mail contact-container">
                    <ImageContainer className="contact">
                        <Image src={arobase} alt="logo email"/>
                    </ImageContainer>
                    <TextContainer>
                        <ContactLink href="mailto:contact@web-boost.fr">contact@web-boost.fr</ContactLink>
                    </TextContainer>
                </ContactContainer>

                <ContactContainer className="location contact-container">
                    <ImageContainer className="contact">
                        <Image src={location} alt="logo localisation" />
                    </ImageContainer>
                    <TextContainer>
                        <Text>57 Rue de la ville</Text>
                        <Text>67460 Souffelweyersheim</Text>
                    </TextContainer>
                </ContactContainer>

                <ContactContainer className="malt contact-container">
                    <ContactLink href="https://www.malt.fr/profile/julien3" target="_blank">
                        <ImageContainer className="contact">
                            <Image src={malt} alt="logo plateforme malt"/>
                        </ImageContainer>
                        <TextContainer>
                            <Text>{i18next.t("contact-section-malt")}</Text>
                        </TextContainer>
                    </ContactLink>
                </ContactContainer>

                <ContactContainer className="codeur contact-container">
                    <ContactLink href="https://www.codeur.com/-webboostfr" target="_blank">
                        <ImageContainer className="contact">
                            <Image src={codeur} alt="logo plateforme codeur"/>
                        </ImageContainer>
                        <TextContainer>
                            <Text>{i18next.t("contact-section-codeur")}</Text>
                        </TextContainer>
                    </ContactLink>
                </ContactContainer>
            </Container>

            {/* <NetworksContainer>
                <Text className="contact-network">{i18next.t("contact-section-network")}</Text>
                <Content>
                    <StyledLink href="#" target="_blank" className="contact-network">
                        <Linkedin />
                    </StyledLink>

                    <StyledLink href="#" target="_blank" className="contact-network">
                        <Facebook />
                    </StyledLink>

                    <StyledLink href="#" target="_blank" className="contact-network">
                        <Instagram />
                    </StyledLink>

                    <StyledLink to="https://wa.me/+33666655963" target="_blank" className="contact-network">
                        <Whatsapp />
                    </StyledLink>
                </Content>
            </NetworksContainer> */}
        </Section>
    )
}

export default ContactSection